@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ReviewInfoTile {
  .title {
    @include label-medium;
    color: $NeutralVariant30;
    display: block;
    opacity: 0.8;
    margin-bottom: 4px;
  }
  .not-completed-msg {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 7px;
    .red-exclamation-point-circle {
      display: block;
      margin-top: 1px;
      margin-right: 5px;
    }
    .title-required {
      color: #B3261E;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
    }
    .input-with-error {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    .go-to-pg {
      background: transparent;
      border: 0px;
      letter-spacing: 0.5px;
      text-decoration: underline;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-left: 8px;
      color: #B3261E;
    }
  }
  .data {
    @include body-large;
    color: $NeutralVariant30;
    display: block;
    word-break: break-word;
  }
}
