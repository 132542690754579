@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.AccountInfo {
  .account-review-block {
    position: relative;
    margin: 16px 20px;
    max-width: 1350px;

    h5 {
      @include headline-medium;
      margin-top: 40px;

      &.first-title {
        margin-top: 64px;
      }

      margin-bottom: 28px;
    }

    .account-review-tiles {
      display: grid;
      grid-template-columns: repeat(3, min-content);

      &.four-columns {
        width: 1152px;
        grid-template-columns: repeat(4, min-content);
      }

      grid-column-gap: 24px;
      grid-row-gap: 24px;

      .account-review-tile {
        width: 360px;

        &.smaller {
          width: 100px;
        }

        @include spaced-between-flexbox(flex-start, nowrap, column);

        p {
          margin: 0;

          &:first-child {
            margin-bottom: 4px;
            @include label-medium;
            opacity: 0.8;
            color: $LightOnSurfaceVariant;
          }

          @include body-large;
          white-space: nowrap;
        }
      }

      .MuiInputBase-root {
        width: 360px;
        height: 48px;
      }

      .address-edit {
        @include spaced-between-flexbox(flex-start);
        gap: 12px;

        .address {
          .MuiInputBase-root {
            width: 200px;
            height: 48px;
          }
        }

        .MuiInputBase-root {
          width: 100px;
          height: 48px;
        }
      }
    }
  }

  .review-loading-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }

  .edit-icon-btn {
    position: absolute;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    padding: 7px;
    right: 40px;

    &:hover {
      background: rgba(0, 97, 164, 0.1);
    }

    &:active {
      background: rgba(0, 97, 164, 0.4);
    }

    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }
  }

  .btns-container {
    position: absolute;
    right: 0;

    .save-btn {
      @include blue-contained-button;
      height: 32px;

      .loading-spinner {
        filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
        margin-right: 8px;
        $spinnerSize: 16px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }

    .cancel-btn {
      @include outlined-button;
      height: 32px;
      margin-left: 12px;
    }
  }

  hr {
    margin-top: 32px;
  }

  .no-inputs-dialog {
    margin-left: auto;
    margin-right: auto;
    width: 1310px;
    height: 220px;
    @include label-medium;
    @include spaced-between-flexbox(center, nowrap, column);

    p {
      &:first-child {
        font-weight: 600;
      }

      margin: 4px;
    }

    background: $Background1;
    border: 1px solid #79747e;
    color: #79747e;
    margin-top: 32px;
    align-items: center;
  }
}