@import "../../styles/typography.scss";
@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";

.Wiz1 {
  .MuiPaper-root {
    @include autocomplete-dropdown;
  }
  &.step-2,
  &.step-3 {
    p {
      max-width: 680px;
    }
  }
}
