@import '../../styles/typography.scss';
@import '../../styles/palette.scss';

.ToolTip {
  background-color: $ToolTipBackgroundGrey;
  padding: 20px 69px 32px 32px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  order: 2;
  flex-grow: 0;
  .MuiSvgIcon-root {
    display: block;
    float: left;
    width: 45px;
    height: 44px;
  }
  h5 {
    @include headline-small;
    color: #49454F;
    display: block;
    float: left;
    margin-left: 9px;
    line-height: 40px;
  }
  .body-content {
    margin-top: 14px;
    @include body-medium;
  }
}
