.GenPasswordReqList {
  .requirements-container {
    display: flex;
    gap: 26px;
    width: 100%;
    & > div { // column
      display: flex;
      flex-direction: column;
    }
  }
  .bulleted-pass-requirement-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
  }
  .PasswordStrengthIndicator {
    .indicator-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .password-strength-label-container {
      text-align: right;
      .hidden-label {
        visibility: hidden;
      }
    }
  }
}
