@import './palette.scss';

@mixin typography-helper {
  font-family: 'Roboto', Arial, sans-serif;
  font-style: normal;
  // color: $Primary10; // $DarkSurface OR $Neutral10 ¯\_(ツ)_/¯
  // Or this grey?
  color: #49454f;
}

// Display

@mixin display-large {
  @include typography-helper;
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
  font-weight: 400;
}

@mixin display-medium {
  @include typography-helper;
  font-size: 45px;
  line-height: 52px;
  font-weight: 400;
}

@mixin display-small {
  @include typography-helper;
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
}

// Headline

@mixin headline-large($fontFamily: 'Roboto', $serif: sans-serif) {
  @include typography-helper;
  font-family: $fontFamily, $serif;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

@mixin headline-medium() {
  @include typography-helper;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

@mixin headline-small {
  @include typography-helper;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

// Title

@mixin title-large {
  @include typography-helper;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

@mixin title-medium($fontFamily: 'Roboto', $serif: sans-serif) {
  @include typography-helper;
  font-family: $fontFamily, $serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin title-small {
  @include typography-helper;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

// Label

@mixin label-large {
  @include typography-helper;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.075px;
}

@mixin label-medium {
  @include typography-helper;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

@mixin label-small {
  @include typography-helper;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

// Body

@mixin body-large {
  @include typography-helper;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

@mixin body-medium {
  @include typography-helper;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  font-weight: 400;
}

@mixin body-small {
  @include typography-helper;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: 400;
}
