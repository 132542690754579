@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bread-crumb {
    margin-top: 24px;
    margin-left: 80px;
    width: 100%;

    .bread-crumb-btn {
      @include title-large;
      font-weight: 400;

      .bread-crumb-title {
        color: #0061A4;
      }

      border: 0px;
      background-color: transparent;
      color: #0061A4;

      &:hover {
        cursor: pointer;

        h4,
        .back-icon {
          color: black;
        }
      }

      .back-icon {
        margin-top: -6px;
        margin-right: 12px;
        color: #0061A4;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &:first-child {
      margin-top: 8px
    }

    .MuiButton-root {
      @include label-large;
      @include text-only-button;
      white-space: nowrap;
      background: #D0D9EE;
      filter: drop-shadow(0px 4px 8px rgba(0, 49, 93, 0.2));
      border-radius: 100px;
      color: $BlueSteel;
      height: 40px;
      margin-left: 20px;
    }

    p {
      @include display-small;
      white-space: nowrap;
      font-size: 32px;
      margin: 0px;
      font-family: 'Manrope';
      line-height: 40px;
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    margin-top: 11px;

    .message-container {
      @include body-large;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 550px;
      width: 577px;
      background-color: #FAFAFD;
      border-radius: 24px;
      background: $Container2;
      border: 1px solid $Outline;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      padding: 40px 48px;

      .message-form {
        .first-paragraph {
          margin-bottom: 24px;
        }

        .MuiFormLabel-root {
          width: 481px;
          margin-bottom: 5px;
          font-weight: 500;
        }

        p {
          width: 481px;
          font-size: 16px;
          margin-bottom: 6px;
        }

        .subject {
          width: 481px;
          height: 56px;
          margin-bottom: 24px;
        }

        .note {
          .MuiInputBase-root {
            height: 128px;

            .MuiInputBase-input {
              margin-bottom: auto;
            }
          }
        }
      }

      .MuiButton-root {
        @include blue-contained-button;
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
        margin-left: auto;
      }
    }

    gap: 24px;

    .MuiBox-root {
      background-image: url("../../images/mountain-image.png");
      background-repeat: no-repeat;
      width: 280px;
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: $BlueSteel;
      color: $Primary100;

      .info-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 60px 40px;
        font-size: 16px;

        .contact-icon {
          width: 48px;
          height: 48px;
        }

        h2 {
          @include headline-medium;
          margin: 30px 0px 24px 0px;
          margin-bottom: 20px;
          color: #FFFFFF;
        }
      }
    }
  }
}