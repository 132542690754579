@import '../../styles/mixins.scss';

@mixin font-and-colors {
  font-family: 'Manrope', Arial, Helvetica, sans-serif;
  color: #49454F;
  font-style: normal;
}

@mixin start-btn-icon {
  > [class^='MuiButton-icon'],
  > [class*=' MuiButton-icon'] {
    display: inline-block;
    > svg {
      margin-top: -2px;
    }
  }
}

.page-loading-wrapper {
  @include spaced-between-flexbox(center);
  margin: 80px;
  .custom-loading-spinner {
    $spinnerSize: 62px;
    height: $spinnerSize;
    width: $spinnerSize;
    @include spin-infinitely();
  }
}

.ValReport {
  .bread-crumb {
    margin-top: 24px;
    margin-left: 48px;
    display: inline-block;
    .bread-crumb-btn {
      @include title-large;
      font-weight: 400;
      .bread-crumb-title{
        color: #0061A4;
      }
      border: 0px;
      background-color: transparent;
      color: #0061A4;
      &:hover {
        cursor: pointer;
        h4,
        .back-icon {
          color: black;
        }
      }
      .back-icon {
        margin-top: -6px;
        margin-right: 12px;
        color: #0061A4;
      }
    }
  }
  > .splash-content-wrapper {
    margin: 0 auto;
    margin-top: 50px;
    > .splash-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 35px;
      .headline-btn {
        @include light-filled-button;
      }
      > button {
        background: #FAFAFD;
        color: #0061A4;
        font-weight: 600;
        @include start-btn-icon;
      }
      > h2 {
        @include font-and-colors();
        display: inline-block;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        > .user-name {
          color: #007BCC;
        }
      }
      > button {
        display: inline-block;
        margin-left: 25px;
      }
    }
    .loading-wrapper {
      @include spaced-between-flexbox(center);
      margin: 80px;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    > .splash-body-wrapper {
      background-color: white;
      border-radius: 24px;
      padding: 30px 10px;
      margin-bottom: 30px;
      > .splash-graphic {
        margin: 0 auto;
        margin-bottom: 40px;
        display: block;
        width: 460px;
      }
      > .title {
        @include title-medium("Manrope");
        text-align: center;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      > p {
        @include body-large();
        text-align: center;
        margin-bottom: 0px;
      }
      > .button-wrapper {
        margin-top: 10px;
        > button {
          @include text-only-button(underline);
          @include start-btn-icon;
          margin: 0 auto;
          display: block;
        }
      }
    }
    .btn-container {
      @include spaced-between-flexbox(center);
      gap: 12px;
      margin: 24px auto;
      .MuiButtonBase-root {
        color: #999ba5;
        border-radius: 50%;
        height: 42px;
        width: 42px;
        padding: 2px;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
    a.MuiButtonBase-root {
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 880px) {
  .SplashPage {
    > .splash-content-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      width: auto;
    }
  }
}
