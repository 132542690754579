@import "../styles/palette.scss";
@import "../styles/mixins.scss";
@import "../styles/typography.scss";

#payment-form {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #49454F;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .card-number,
  .exp-date,
  .cvv {
    width: 25px;
    border: 1px solid #999ba5;
    width: 380px;
    height: 56px;
    border-radius: 12px;
    padding: 17px 16px;

    &:hover {
      border: 1px solid black;
    }
  }

  .helper-text {
    font-size: 12px;
    margin-left: 16px;
    letter-spacing: 0.4px;
  }

  button {
    width: 200px;
  }

  #payment-message {
    color: #b3261e;
    font-style: italic;
  }

  .inputDiscountCode {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    font-size: 12px;
    text-align: left;
    width: 125px;
    font-weight: 400;
    margin: 0px 20px 0px 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  .specialOffersRadio {
    font-size: 12px;
  }

  .discountContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .MuiFormControl-root{
    .MuiInputBase-root {
      box-shadow: none;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #999ba5;
    &:hover {
      border: 1px solid black;
    }
  }

  div.MuiInputBase-root:hover
  fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
    border: 1px solid black !important;
  }

  input {
    color: #49454F;
    opacity: 0.9;
    &::placeholder {
      color: #49454F;
      opacity: 0.7;
    }
  }

  .applyDiscountCode {
    @include label-large;
    @include blue-contained-button;
    border-radius: 100px;
    width: 143px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }

  .discountCodeClearIcon {
    color: #49454f;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 2px;
    margin-right: 5px;
    margin-left: 2px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 97, 164, 0.1) !important;
    }

    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }

    &:active {
      background-color: rgba(0, 97, 164, 0.4) !important;
    }
  }

  .codeError {
    margin: 3px 0px 0px 12px;
    .title-required {
      font-size: 12px;
      margin: 5px;
      color: #B3261E;
    }
  }
}
