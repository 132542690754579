@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ReviewDropdown {
  .header-row {
    @include spaced-between-flexbox(flex-start);
    justify-content: flex-start !important;
    align-items: center;
    height: 64px;
    gap: 10px;
    padding: 16px 36px;
    margin: 8px -40px 12px -40px;
    &:hover {
      background: rgba(0, 97, 164, 0.1);
    }
    &:active {
      background: rgba(0, 97, 164, 0.2);
    }
    &:focus-visible {
      outline: 2px solid $InitioBlue;
    }
    h5 {
      @include headline-medium;
      margin: 0 !important;
      color: $NeutralVariant30;
    }
    .edit-in-wizard-btn,
    .chevron-btn {
      .MuiSvgIcon-root {
        color: #49454f;
      }
      @include spaced-between-flexbox(center);
      align-items: center;
      border: none;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      background: none;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1) !important;
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4) !important;
      }
    }
    .edit-in-wizard-btn {
      margin-left: auto;
      margin-right: 6px;
      .MuiSvgIcon-root {
      color: #79747E;
      }
    }
    .upward-circle-chevron {
      $upRotation: 180deg;
      -webkit-transform: rotate($upRotation);
      -moz-transform: rotate($upRotation);
      -ms-transform: rotate($upRotation);
      -o-transform: rotate($upRotation);
      transform: rotate($upRotation);
    }
    .downward-circle-chevron {
      $downRotation: 360deg;
      -webkit-transform: rotate($downRotation);
      -moz-transform: rotate($downRotation);
      -ms-transform: rotate($downRotation);
      -o-transform: rotate($downRotation);
      transform: rotate($downRotation);
    }
  }
}
