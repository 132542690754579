@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";
.Wiz2 {
  .MuiSlider-root {
    height: 14px;
    > span.MuiSlider-mark {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      $finalAndInitialSliderDotOffsets: 7px;
      @include mui-slider-first-and-last-indicator-offsets(
        20,
        $finalAndInitialSliderDotOffsets,
        $finalAndInitialSliderDotOffsets,
      );
    }
  }
  .MuiSlider-rail {
    width: 101.3%;
  }
  .MuiSlider-track {
    background: linear-gradient(90deg, #5F8DF5 -3.73%, #2159BE 102.99%);
    border-radius: 100px 100px 100px 100px;
  }
  .MuiSlider-valueLabel {
    background-color: #003E66;
    color: #fff;
  }
  &.step-1 {
    .debt-cash-inputs-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .debt-cash-input {
        width: 370px;
        margin-bottom: 20px;
      }
    }
    .ebitda-check {
      margin-left: 7px;
      .MuiSvgIcon-root {
        height: 18px;
        width: 18px;
        margin-bottom: 7px;
      }
      .MuiFormControlLabel-label {
        @include body-medium;
        margin-bottom: 7px;
      }
    }
  }
  &.step-2 {
    width: 750px;
    margin: 0 auto;
    p.description {
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      color: #49454F;
    }
  }
}
