.Wiz3 {
  &.step-2 {
    .money-val-inputs {
      width: 350px;
    }
    #pre-or-post-money-notes {
      margin-bottom: 0px;
      font-size: 13px;
    }
    .pre-post-money-labels {
      .MuiTypography-root {
        font-size: 13px;
      }
    }
  }
  &.step-3 {
    &.current-round-fo-funding {
      > svg {
        color: #4295F3!important;
      }
      > .title {
        color: #49454F;
        font-family: 'Manrope', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
      }
      > p.description {
        margin-top: 12px;
        margin-bottom: 24px;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: #49454F;
      }
    }
  }
  &.step-5 {
    .secondary-stock-transactions-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .secondary-stock-transaction-input {
        width: 380px;
      }
      .percentage-common-stock-sold-error {
        color: #d32f2f;
        &.hide-error {
          visibility: hidden;
        }
      }
    }
  }
  hr {
    margin: 40px 0px;
  }
  .MuiInputBase-root{
    border-radius: 12px;
  }
}
