@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.Login {
  .login-container {
    @include white-container(448px);
    @include spaced-between-flexbox(center, nowrap, column);
    gap: 20px;
    align-items: center;
    margin: 64px auto;
    .inactive-icon {
      width: 38px;
      height: 38px;
      color: #79747E;
    }
    .inactivity-text{
      @include body-large;
      text-align: center;
      margin: 0;
    }
    .email-verified-header {
      background-color: #ecf9f6;
      width: 352px;
      padding: 27px;
      @include spaced-between-flexbox(center);
      border-radius: 4px;
      gap: 10px;
      .code-verified-check-icon {
        width: 40px;
        margin-top: 5px;
      }
      p {
        @include body-medium;
        color: $Success40;
        margin: 0;
      }
    }
    .error-header {
      background: rgba(249, 222, 220, 0.3);
      width: 352px;
      padding: 27px;
      @include spaced-between-flexbox(center);
      border-radius: 4px;
      gap: 10px;
      .warning-icon {
        svg {
          height: 18px;
          width: 17px;
        }
      }
      p {
        @include body-medium;
        color: #b3261e;
        margin: 0;
      }
    }
    .error-message {
      @include spaced-between-flexbox(flex-start);
      gap: 4px;
      font-size: 12px;
      color: #b3261e !important;
      .MuiSvgIcon-root {
        margin-top: 2px;
        padding: 0;
        height: 14px;
        width: 14px;
        color: #b3261e;
      }
      .forgot-password-error-link, 
      .resend-code-error-link {
        @include btn-to-link-text;
        font-size: 12px;
        font-weight: 400;
        color: #B3261E;
        padding: 0;
        &:hover{
          color: #B3261E;
          opacity: 0.8;
        }
        &:visited{
          color: #B3261E;
          opacity: 0.8;
        }
      }
    }
    .MuiFormControl-root {
      margin-top: 8px;
      &.has-error {
        .MuiInputLabel-shrink {
          color: #B3261E;
        }
      }
    }
    .MuiFormHelperText-root {
      @include body-small;
    }
    .MuiInputBase-root {
      width: 352px;
    }
    h4 {
      @include headline-medium;
      text-align: center;
      margin: 0;
    }
    .reset-password-link,
    .sign-up-link {
      @include text-only-button(underline);
      width: 100%;
      color: $InitioBlue;
    }
    .confirm-login-btn {
      @include blue-contained-button;
      margin-top: 8px;
      width: 100%;
    }
  }
  .new-verif-email {
    @include white-container(448px);
    @include spaced-between-flexbox(center, nowrap, column);
    gap: 20px;
    align-items: center;
    margin: 64px auto;
    .top-icon {
      color: #00BE8A;
      width: 28px;
      height: 28px;
    }
    h4 {
      @include headline-medium;
      text-align: center;
      margin: 0;
    }
  }
}
