@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.Tabs {
  background: #f5f5f5;
  min-height: 100vh;
  &.table-width {
    min-width: 1640px;
  }
  &.sandbox-width {
    min-width: 1100px;
  }
  &.report-width {
    min-width: 1300px;
  }
  .tables-pg-top-nav-buttons {
    @include spaced-between-flexbox(space-around);
    gap: 1px;
    height: 48px;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #fafafd;
    .top-nav-button {
      @include spaced-between-flexbox(center);
      @include body-large;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      gap: 10px;
      border: none;
      background-color: #c7cbdc;
      color: $Primary40;
      &.active {
        pointer-events: none;
        color: #49454f;
      }
      &:hover {
        background-color: #b9bdcd;
      }
      &:active {
        background-color: #abafbe;
      }
      &:focus-visible {
        background-color: #abafbe;
        outline: 1px solid $InitioBlue;
      }
    }
    .top-nav-right {
      flex-grow: 1;
      background: #c7cbdc;
      @include spaced-between-flexbox(flex-end);
      gap: 8px;
      align-items: center;
      padding-right: 16px;
      .priority-dropdown {
        @include select-dropdown;
        color: #49454f;
        height: 28px;
        background-color: #edf1fa;
        border-radius: 4px;
        * {
          border: none;
        }
        border: 1px solid #c9c5ca;
        &:hover {
          * {
            border: none;
          }
        }
        .MuiSelect-select {
          @include spaced-between-flexbox(flex-start);
          align-items: center;
          @include label-medium;
        }
        .MuiSvgIcon-root {
          color: #49454f;
          font-size: 20px;
        }
        .MuiButtonBase-root {
          @include spaced-between-flexbox(flex-start);
          gap: 20px;
          .high-icon {
            color: #ffc043;
          }
          .low-icon {
            color: #49454f;
          }
        }
        .MuiPaper-root {
          margin-top: 16px;
        }
      }
    }
  }
  .tables-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 70px;
    .top-tabs-nav {
      margin-bottom: 32px;
      &.client-inputs {
        margin-bottom: 64px;
      }
      .tab-button {
        @include label-large;
        height: 36px;
        padding-left: 16px;
        padding-right: 16px;
        background: none;
        border: none;
        &:hover {
          border-bottom: 4px solid rgba(0, 97, 164, 0.1);
        }
        &.active-tab {
          font-weight: 600;
          border-bottom: 4px solid $InitioBlue;
        }
      }
      &:after {
        // full-width tab divider
        content: "";
        border-top: 1px solid $Outline;
        display: block;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
    .Tab1,
    .Tab2,
    .Tab3 {
      @include spaced-between-flexbox(center, nowrap, column);
      align-items: flex-start;
    }
  }
  .tables-pg-bottom-nav-buttons {
    position: fixed;
    z-index: 999;
    bottom: 0px;
    width: 100%;
    background: #575e71;
    box-shadow: 0px 1px 0px #dddbda;
    padding-top: 8px;
    padding-bottom: 8px;
    @include spaced-between-flexbox();
    align-items: center;
    .MuiButtonGroup-root {
      .MuiButtonBase-root {
        width: 32px;
        height: 32px;
        background-color: $Secondary50;
        border: 1px solid $Secondary60;
        &:hover {
          background-color: $Secondary40;
        }
        &:active {
          background-color: $Secondary30;
        }
        &:focus-visible {
          background-color: $Secondary40;
          outline: 2px solid $InitioBlue;
        }
      }
      a.MuiButtonBase-root {
        color: #ffffff;
      }
    }
    .left-buttons {
      @include spaced-between-flexbox(center);
      align-items: center;
      margin-left: 16px;
      gap: 6px;
    }
    .right-buttons {
      @include spaced-between-flexbox(flex-start);
      gap: 12px;
      margin-right: 16px;
      .MuiButtonBase-root {
        height: 32px;
        @include tonal-contained-button;
        &.report-btn {
          &:hover {
            background: rgba(158, 202, 255, 1);
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.5);
          }
        }
        &.blue-btn {
          @include blue-contained-button;
        }
      }
      .loading-spinner {
        margin-right: 8px;
        $spinnerSize: 16px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    @media only screen and (max-width: 780px) {
      .right-buttons {
        @include spaced-between-flexbox(center);
        flex-direction: column;
        align-items: center;
        gap: 6px;
      }
    }
  }
  .calc-snackbar {
    .MuiPaper-root {
      margin-top: 48px;
      border-radius: 8px;
      background-color: #313033;
      .MuiSnackbarContent-message {
        @include spaced-between-flexbox(center);
        align-items: center;
        gap: 12px;
        span {
          @include body-medium;
          color: #ffffff;
        }
        .MuiButtonBase-root {
          @include text-only-button-dark;
        }
      }
    }
  }
}

.bottom-arrow-tooltip {
  .MuiTooltip-tooltip {
    margin-bottom: 8px !important;
    background-color: #00497d !important;
    @include body-medium;
    color: #ffffff;
    padding: 6px 8px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #00497d !important;
      }
    }
  }
}

.calc-start-dialog,
.calc-success-dialog,
.calc-failure-dialog,
.re-calc-start-dialog,
.finalize-dialog,
.report-sent-dialog,
.send-dev-email,
.dev-email-sent-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include spaced-between-flexbox();
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 21px;
      .MuiSvgIcon-root {
        color: #79747e;
        height: 36px;
        width: 36px;
      }
      h4 {
        @include headline-medium;
        margin-bottom: 0;
      }
      &.calc-success {
        .MuiSvgIcon-root {
          color: $Success50;
        }
        h4 {
          color: $Success50;
        }
      }
      &.calc-failure {
        .MuiSvgIcon-root {
          color: #b3261e;
        }
        h4 {
          color: #b3261e;
        }
      }
    }
    p,
    li {
      font-family: "Roboto";
      line-height: 24px;
      font-size: 16px;
    }
    .calc-error-message {
      color: #b3261e;
    }
    .box-buttons {
      @include spaced-between-flexbox();
      justify-content: flex-end;
      gap: 10px;
      margin-top: 42px;
      .cancel-btn {
        @include outlined-button;
      }
      .confirm-btn {
        @include blue-contained-button;
      }
    }
  }
}

.loading-inputs-dialog {
  .MuiPaper-root {
    border-radius: 24px;
    padding: 32px;
    .box-header {
      @include spaced-between-flexbox();
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 21px;
      h4 {
        font-weight: 400;
        margin: 0;
      }
    }
    .loading-wrapper {
      @include spaced-between-flexbox(center);
      margin: 20px;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
}

.calc-is-in-progress-dialog {
  .MuiPaper-root {
    @include spaced-between-flexbox(center, nowrap, column);
    align-items: center;
    gap: 16px;
    text-align: center;
    border-radius: 24px;
    padding: 32px;
    .loading-spinner {
      $spinnerSize: 48px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
    h4 {
      @include headline-medium;
      margin-bottom: 0;
    }
    .dialog-content {
      p {
        &:first-child {
          @include label-large;
        }
        &:not(:first-child) {
          @include body-large;
          margin-bottom: 0;
        }
      }
    }
    .homepage-btn {
      @include text-only-button;
    }
    .dialog-finalize-btn {
      margin-left: auto;
      margin-top: 24px;
      .MuiButtonBase-root {
        margin-right: 12px;
        &:not(:last-child) {
          @include outlined-button;
        }
        &:last-child {
          @include blue-contained-button;
        }
      }
    }
  }
}

.loading-inputs-dialog {
  .MuiPaper-root {
    border-radius: 24px;
    padding: 32px;
    .box-header {
      @include spaced-between-flexbox();
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 21px;
      h4 {
        font-weight: 400;
        margin: 0;
      }
    }
    .loading-wrapper {
      @include spaced-between-flexbox(center);
      margin: 20px;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
}
