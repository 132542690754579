@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.MakePayment {
  .page-header-row {
    @include spaced-between-flexbox;
  }

  .header-row {
    @include spaced-between-flexbox;

    .dropdown-btn {
      width: 100%;
      border: 0px;
      text-align: left;
      background-color: transparent;
      border: 0px;
    }

    .edit-in-wizard-btn {
      margin-top: -25px;
      padding-top: 0px;
      background-color: transparent;
      border: 0px;
    }
  }

  .checkout-cols-container {
    display: flex;
    gap: 24px;

    .checkout-col {
      position: relative;

      .checkout-ctrl {
        width: 90%;
        min-width: 200px;

        .MuiFormHelperText-root {
          margin-bottom: 0px;
          padding-bottom: 0px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #49454f;
        }

        .Mui-error {
          color: $LightError;
        }
      }

      .legal-text {
        @include body-small;
        color: #79747E;
        position: absolute;
        bottom: 0px;
      }
      
      .total-price{
        @include title-large;
      }

      .order-summary {
        @include body-large;
        font-size: 14px;
        padding: 24px;
        background: linear-gradient(326.73deg, #d1e4ff 2.09%, #eff0f3 106.43%);
        border-radius: 24px;

        p {
          margin-bottom: 0px;
        }

        hr{
          color: $Outline;
        }

        .summary-bold {
          font-weight: 700;
        }

        .total-separator{
          color:#49454F !important;
        }

        ul {
          li {
            font-size: 14px;
            color: #49454f;
          }
          margin: 0;
        }

        .subscription-includes {
          @include body-medium;
          .sub-list {
            list-style-type: disc;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
