@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

@mixin audit-risk-text() {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #49454f;
}

$slidersBorderRadius: 24px;

@mixin overlays() {
  width: 580px;
  margin-top: -30px;
  height: 280px;
  position: absolute;
  border-radius: $slidersBorderRadius;
}

.Sandbox {
  &.success-container {
    margin: 0 auto;
    background: #fafafd;
    box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
    border-radius: 28px;
    padding: 40px 48px 30px;
    max-width: 880px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      @include headline-large("Manrope");
      margin-bottom: 16px;
    }

    a {
      margin-top: 24px;
    }

    .success-msg-container {
      padding: 32px 65px;
      background: #f2faf3;
      border-radius: 4px;
      max-width: 784px;
      margin: 24px auto;

      p {
        color: #008862;
      }
    }

    .success-link {
      @include text-only-button(underline);
      color: $InitioBlue;
      margin-top: "24px";
    }
  }

  .back-link-block {
    margin-top: 24px;
    margin-left: 52px;

    h4 {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #0061a4;
    }

    .back-link-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100px;
      color: #0061a4;
      background-color: transparent;
      border-width: 0px;
      padding: 0px;

      .MuiSvgIcon-root {
        margin-top: 2px;
      }
    }
  }

  .valuation-container {
    $leftColWidth: 640px;
    $rightColWidth: 288px;
    $marginBetweenCols: 24px;

    width: $leftColWidth + $rightColWidth + $marginBetweenCols;
    margin: 0 auto;

    margin-bottom: 45px;

    .headline-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 969px;
      margin-bottom: 40px;

      button.MuiButtonBase-root {
        &.MuiButton-root {
          &.MuiButton-contained {
            margin-left: 16px;

            border-radius: 100px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.075px;
            color: #ffffff;
            font-size: 12px;

            &.top-btn-instance {
              filter: drop-shadow(2px 5px 4px rgba(0, 49, 93, 0.2));
              @include tonal-contained-button;
              height: 40px;
              &.finalize {
                @include blue-contained-button;
                background-color: $Primary40;
              }
            }

            &.secondary-top-btn {
              background: #d0d9ee;
              color: #303d5c;

              &:hover {
                box-shadow: none !important;
                background-color: #d1e4ff;
              }

              &:focus-visible {
                border: 2px solid #4295f3;
              }

              &.printer-btn {
                $printerBtnWidth: 29px;
                width: $printerBtnWidth;
                min-width: $printerBtnWidth;
                padding: 6px 18px;
                width: 143px;
                height: 40px;
                white-space: nowrap;

                > svg {
                  margin-right: 5px;
                  width: 18px;
                }
              }

              &.refresh-btn {
                width: 103px;

                > svg {
                  margin-right: 5px;
                  width: 18px;
                }
              }

              &.optimize-btn {
                width: 123px;

                > svg {
                  margin-right: 5px;
                  width: 18px;
                }
              }
            }
          }
        }
      }

      h2 {
        display: inline-block;
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: #49454f;
        margin-top: 4px;
      }
    }

    .sandbox-scrolling-wrapper {
      &.user-view {
        height: 72vh;
        &::-webkit-scrollbar {
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border: 5px solid transparent;
          border-radius: 100px;
          background-color: #999ba5;
          background-clip: content-box;
        }
      }
      padding-right: 20px;
      padding-top: 5px;
      width: $leftColWidth + $rightColWidth (+45px);

      .top-explanation {
        background: #fafafd;
        border-radius: 24px;
        padding: 24px 40px 24px;

        font-family: "Roboto", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: #49454f;

        p {
          margin: 0;
        }
      }

      > .info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        > .info-container-left-col {
          width: $leftColWidth;
          margin-right: 12px;

          .updated-price-loading-wrapper {
            .updated-price-loading-overlay-text {
              @include overlays();
              z-index: 25;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;

              > .custom-loading-spinner {
                $spinnerSize: 48px;
                height: $spinnerSize;
                width: $spinnerSize;
                margin-bottom: 17px;
                @include spin-infinitely();
              }

              span {
                @include headline-medium;
              }
            }

            .updated-price-loading-overlay {
              @include overlays();
              z-index: 10;
              opacity: 0.8;
              height: 350px;
              background: #fafafd;
            }
          }

          .value-sliders-wrapper {
            border-radius: $slidersBorderRadius;
            background: #fafafd;
            padding: 24px 0px 40px 48px;
            margin-top: 24px;
            position: relative;

            .MuiSlider-root {
              &.Mui-disabled {
                .MuiSlider-track {
                  border: none;
                  background: linear-gradient(
                    to right,
                    #aeaaae 0%,
                    #cccccc 50%
                  );
                }
                .MuiSlider-mark {
                  background-color: #aeaaae;
                }
                .MuiSlider-valueLabel {
                  @include label-medium;
                  opacity: 0.6;
                  background-color: #cccccc;
                  height: 28px;
                }
                .MuiSlider-thumb {
                  width: 16px;
                  height: 16px;
                  outline: 1px solid #fafafd;
                }
              }
            }

            .value-sliders-headline-wrapper {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: top;
              margin-bottom: 40px;

              h2 {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: #49454f;
              }
            }

            .per-share-price-wrapper {
              background-color: #42a279;
              border-radius: 100px 0px 0px 100px;
              width: 230px;
              height: 60px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;

              .per-share-label {
                letter-spacing: 0.075px;
                font-family: "Roboto", sans-serif;
                font-family: sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
                margin-left: 6px;
              }

              h2 {
                margin-left: 32px;
                margin-bottom: 0px;
                font-family: "Manrope", sans-serif;
                font-family: sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 32px;
                color: #fff;

                .MuiSvgIcon-root {
                  margin-left: 0px;
                  font-size: 32px;
                  line-height: 40px;
                  margin-top: -5px;
                  margin-right: -6px;
                }
              }
              &.medium-risk {
                background-color: #f4c543;
                .per-share-label {
                  color: #49454f;
                }
                h2 {
                  color: #49454f;
                }
              }
            }
          }

          .audit-risk-info-and-slider {
            padding: 24px 48px;
            background: #fafafd;
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
            border-radius: 24px;
            margin-top: 24px;

            > .iterations-bar {
              width: 544px;
              background: #eff0f3;
              height: 36px;
              padding: 8px 12px;
              border-radius: 8px;

              > .iterations-text {
                color: #79747e;
                font-family: Roboto, Arial, Sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.5px;

                flex-wrap: nowrap;
                flex-direction: row;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }
            }

            > h3 {
              @include audit-risk-text();
              font-size: 24px;
              line-height: 24px;
              margin-bottom: 0;

              > span {
                margin-left: 8px;
              }
            }

            > p {
              @include audit-risk-text();
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.25px;
            }
          }

          .outcomes-text-block {
            padding: 24px 48px 32px;
            background: #fafafd;
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
            border-radius: 24px;
            margin-top: 24px;
            margin-bottom: 24px;

            > p.outcomes-text {
              font-family: Roboto, Arial, Sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.25px;
              margin-bottom: 0;
            }
          }

          .key-terms-block {
            padding: 24px 48px 40px;
            background: #fafafd;
            border-radius: 24px;

            > h3.key-terms-title {
              font-family: Roboto, Arial, Sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 32px;
              color: #49454f;
              margin-bottom: 36px;
            }

            > div.key-terms-entry {
              border-left: 4px solid #40c486;
              padding-left: 12px;

              > p.key-terms-explanation {
                font-family: Roboto, Arial, Sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.25px;

                > span.key-term {
                  font-weight: 700;
                }
              }
            }
          }
        }

        .info-container-right-col {
          width: $rightColWidth;
          margin-left: 12px;
          margin-top: 24px;

          > .right-col-wrapper {
            background: #fafafd;
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
            border-radius: 24px;
            padding: 24px 48px 40px;

            > h3 {
              @include audit-risk-text();
              font-size: 24px;
              line-height: 32px;
              margin-bottom: 36px;
            }

            > h4 {
              @include audit-risk-text();
              font-size: 16px;
              line-height: 24px;
            }

            > hr {
              width: 192px;
              margin: 26px auto 22px;
            }
          }
        }
      }
    }
  }
}

.no-outcome-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;

    .box-header {
      @include body-large;
      @include spaced-between-flexbox();
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 21px;
      .MuiSvgIcon-root {
        color: $Tertiary50;
        height: 36px;
        width: 36px;
      }
      h4 {
        @include headline-medium;
        color: $Tertiary50;
        font-weight: 400;
        margin: 0;
      }
    }

    .got-it-btn {
      @include blue-contained-button;
      width: auto;
      margin-left: auto;
    }
  }
}

.finalize-arrow-tooltip {
  .MuiTooltip-tooltip {
    background-color: #00497d !important;
    @include body-large;
    color: #ffffff;
    padding: 16px 24px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #00497d !important;
      }
    }
  }
}

.optimize-dropdown {
  margin-top: 8px;
  .MuiPaper-root {
    @include select-dropdown;
    .active {
      background-color: rgba(66, 149, 243, 0.08);
      box-shadow: 4px 0 0 $InitioBlue inset;
    }
  }
}
