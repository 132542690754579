.FormControlRadioBtn {
  &.MuiFormControlLabel-root {
    align-items: flex-start;
    > .MuiCheckbox-root {
      &.Mui-checked {
        color: #4295F3;
      }
      &.ctrl {
        padding: 0px;
        padding-top: 2px;
        margin-right: 5px;
      }
    }
  }
}
