@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";
@import "../../styles/palette.scss";

.Signup {
  .user-agreement-error {
    display: flex;
    align-items: top;
    margin-left: 15px;
    margin-top: 5px;
    &.user-agreement-error-hidden {
      visibility: hidden;
    }
  }
  .signup-white-wrapper {
    @include white-container(448px);
    @include spaced-between-flexbox(center, nowrap, column);
    gap: 22px;
    align-items: center;
    margin: 64px auto;
    h4 {
      @include headline-medium;
      text-align: center;
      margin: 0;
    }
    .error-message {
      @include spaced-between-flexbox(flex-start);
      gap: 4px;
      font-size: 12px;
      color: #b3261e !important;
      .MuiSvgIcon-root {
        margin-top: 2px;
        padding: 0;
        height: 14px;
        width: 14px;
        color: #b3261e;
      }
    }
    .continue-button,
    .sign-up-button {
      @include blue-contained-button;
      margin-top: 20px;
      width: 100%;
    }
    .has-error {
      .MuiInputLabel-shrink {
        color: #b3261e;
      }
    }
    .MuiFormHelperText-root {
      @include body-small;
      height: 20px;
    }
    .first-step {
      margin: 0 auto;
      > .confirm-client-or-partner-btn {
        border-radius: 100px;
        text-transform: none;
        width: 100%;
        margin-top: 32px;
        background-color: #007bcc;
        font-size: 14px;
        line-height: 20px;

        font-family: "Roboto SemiBold", "Roboto", Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 0.075px;
      }
      > .client-partner-form-control {
        display: block;
      }
      > .MuiFormControl-root {
        > #demo-radio-butts-group-label {
          color: #49454f;
          margin-bottom: 15px;
        }
      }
    }
    .top-icon {
      width: 21px;
      height: 18px;
      color: #00be8a;
    }
  }
  .login-btn {
    @include text-only-button(underline);
    width: 100%;
    color: $InitioBlue;
    margin: 24px auto 0px;
  }
  .back-btn {
    @include text-only-button(underline);
    width: 100%;
    color: $InitioBlue;
    margin: 24px auto 0px;
  }
  .user-agreement-and-privacy-text,
  .email-text-marketing-text {
    @include body-medium;
    line-height: 20px;
  }
  .conditions-link {
    color: $InitioBlue;
    &:hover,
    &:visited {
      color: $InitioBlue;
      opacity: 0.8;
    }
  }
  .email-sent-wrapper {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
    > p {
      color: #49454f;
    }
    > .go-to-login {
      @include text-only-button(underline);
      font-size: 16px;
    }
  }
  .code-verified-wrapper {
    > .code-verified {
      margin-top: 20px;
      background: #ecf9f6;
      border-radius: 4px;
      width: 352px;
      height: 72px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      > .code-verified-check-icon {
        display: inline-block;
        color: #00be8a;
        margin-right: 11px;
      }
      > .code-verified-msg {
        color: #006c4d;
      }
    }
    > .nav-to-homepage {
      @include btn-to-link-text;
      width: 160px;
      display: block;
      margin: 20px auto;
      > .logging-in-icon {
        display: block;
        margin: 0 auto;
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
    label {
      &.MuiFormLabel-root {
        &.Mui-focused {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
}
