@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.file-upload-and-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  .drag-and-drop-area {
    position: relative;
    border: 2px dashed #49454f;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 200px;
    .file-upload-loading-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 24px;
      z-index: 999;
      background: #fafafd;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    &.file-dragging,
    &:hover {
      cursor: default !important;
      border: 2px dashed #0967a7;
      background-color: #f3f5fa;
      color: #0967a7;
    }
    .browse-or-drop-content {
      @include spaced-between-flexbox(center);
      align-items: center;
      gap: 10px;
      .MuiSvgIcon-root {
        height: 60px;
        width: 40px;
      }
    }
    .browse-or-drop-text {
      @include spaced-between-flexbox(flex-start, nowrap, column);
      button {
        padding: 0;
      }
      &:first-child {
        @include body-large;
      }
      .max-file-size {
        @include body-small;
      }
    }
    .file-type-icons {
      display: block;
      @include body-small;
      @include spaced-between-flexbox(center);
      align-items: center;
      gap: 8px;
      .icon {
        margin-top: -5px;
        display: inline-block;
        width: 20px;
        height: 30px;
        path {
          fill: #49454f;
        }
      }
      &:hover {
        cursor: default;
        color: $Primary40;
        .icon {
          path {
            fill: $Primary40;
          }
        }
      }
    }
    .browse-files-btn {
      background-color: transparent;
      border: 0px;
      text-decoration: underline;
      color: #4295f3;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    .file-style {
      display: none;
    }
  }
  .uploaded-files-display {
    width: 50%;
    .file {
      padding: 16px 20px 24px;
      background-color: #ecf9f6;
      border-radius: 24px;
      margin-left: 15px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.25px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .upload-complete {
        margin-top: 8px;
        display: block;
      }
      .file-name-and-checkmark-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .checkmark-with-underline {
          color: #00be8a;
          width: 17.2px;
          height: 22px;
          margin-right: 8px;
          display: inline-block;
        }
        span {
          word-wrap: break-word
        }
      }
      .clear-icon {
        color: #999ba5;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 2px;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
  }
}

.file-type-tooltip {
  .MuiTooltip-tooltip {
    @include body-small;
    margin-bottom: 8px !important;
    background-color: #313033 !important;
    color: #ffffff;
    padding: 12px 12px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #313033 !important;
      }
    }
    p {
      margin: 0;
      span {
        font-weight: 600;
      }
    }
  }
}
