@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';

.ReviewAndCheckout {
  .review-loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 24px;
    z-index: 999;
    background: #fafafd;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    padding-top: 200px;
    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
  .warning-block {
    background-color: rgba(249, 222, 220, 0.3);
    border-radius: 4px;
    padding: 32px 32px 20px;
    margin-bottom: 34px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .warning-icon {
      display: block;
      margin-top: -5px;
      margin-right: 8px;
    }
    .warning-message {
      display: block;
      h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: #B3261E;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.25px;
        color: #49454F;
      }
    }
  }
  .review-header-row {
    h3 {
      @include headline-large('Manrope');
    }
  }
  .page-header-row {
    @include spaced-between-flexbox;
    h3{
      @include headline-large('Manrope');
    }
    .top-submit-info-btn {
      @include blue-contained-button;
    }
  }
  .checkout-cols-container {
    display: flex;
    .checkout-col {
      .checkout-ctrl {
        width: 90%;
        min-width: 200px;
        .MuiFormHelperText-root {
          margin-bottom: 0px;
          padding-bottom: 0px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #49454F;
        }
        .Mui-error {
          color: $LightError;
        }
      }
      .order-summary {
        padding: 24px;
        background: linear-gradient(326.73deg, #D1E4FF 2.09%, #EFF0F3 106.43%);
        border-radius: 24px;
        p {
          margin-bottom: 0px;
        }
        ul {
          li {
            font-size: 14px;
            color: #49454F;
          }
        }
      }
    }
  }
  .review-block {
    padding-left: 48px;
    .ReviewInfoTile {
      margin-bottom: 20px;
    }
  }
}
