@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

@mixin spaced-between-flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.ReviewAndSign {
  &.Review {
    .warning-block {
      background-color: rgba(249, 222, 220, 0.3);
      padding: 40px 32px;
      margin-bottom: 24px;
      border-radius: 4px;
      .warning-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        color: #b3261e;
        h2 {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #B3261E;
        }
      }
      p {
        margin-bottom: 0 !important;
        margin-left: 45px;
      }
    }
    .page-header-row {
      @include spaced-between-flexbox;
      align-items: center;
      h4 {
        @include headline-large('Manrope');
        margin: 0 !important;
        color: #49454f;
      }
      .top-submit-info-btn {
        @include blue-contained-button;
      }
      margin-bottom: 24px;
    }

    .header-row {
      @include spaced-between-flexbox;

      .dropdown-btn {
        width: 100%;
        border: 0px;
        text-align: left;
        background-color: transparent;
        border: 0px;
      }
    }

    .sub-title {
      @include label-large;
      color: #43474E;
      margin: 12px auto 12px 24px;
    }

    .review-tiles-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 300px);
      justify-content: space-around;
      row-gap: 24px;
      margin-bottom: 20px;
    }

    .checkout-cols-container {
      display: flex;

      .checkout-col {
        position: relative;

        .checkout-ctrl {
          width: 90%;
          min-width: 200px;

          .MuiFormHelperText-root {
            margin-bottom: 0px;
            padding-bottom: 0px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #49454f;
          }

          .Mui-error {
            color: $LightError;
          }
        }

        .legal-text {
          font-size: 12px;
          position: absolute;
          bottom: 0px;
        }

        .order-summary {
          font-size: 14px;
          padding: 24px;
          background: linear-gradient(
            326.73deg,
            #d1e4ff 2.09%,
            #eff0f3 106.43%
          );
          border-radius: 24px;

          p {
            margin-bottom: 0px;
          }

          .summary-bold {
            font-weight: bold;
          }

          ul {
            li {
              font-size: 14px;
              color: #49454f;
            }
          }

          .subscription-includes {
            .sub-list {
              list-style-type: disc;
            }

            p {
              margin-bottom: 0px;
            }

            ul {
              li {
                color: #49454f;
              }
            }
          }
        }
      }
    }
  }
  &.Sign {
    h4 {
      margin-bottom: 40px !important;
    }
    .sign-info-box {
      display: flex;
      align-items: center;
      padding: 24px;
      gap: 24px;
      background: linear-gradient(326.73deg, #d1e4ff 2.09%, #eff0f3 106.43%);
      border-radius: 24px;
      margin-bottom: 24px;
      .sign-info-text {
        margin: 0 !important;
      }
    }
    .MuiInputBase-root {
      width: 380px;
      height: 56px;
      border-radius: 12px;
    }
  }
}
