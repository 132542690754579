@import '../../styles/mixins.scss';

.ResetPassword {
  .forgot-password-container {
    @include white-container(448px);
    @include spaced-between-flexbox(center, nowrap, column);
    gap: 22px;
    align-items: center;
    margin: 64px auto;
    .top-icon {
      width: 21px;
      height: 18px;
      color: #00be8a;
    }
  }
  .error-message {
    @include spaced-between-flexbox(flex-start);
    gap: 4px;
    font-size: 12px;
    color: #b3261e !important;
    .MuiSvgIcon-root {
      margin-top: 2px;
      padding: 0;
      height: 14px;
      width: 14px;
      color: #b3261e;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    margin-top: 8px;
    &.has-error{
      .MuiInputLabel-shrink{
        color: #B3261E;
      }
    }
  }
  .MuiFormHelperText-root{
    @include body-small;
  }
  .success-alert {
    margin: 24px auto 0px;
    background-color: #F2FAF3;
    border-radius: 4px;
    width: 352px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00A406;
    .custom-check-icon {
      font-size: 22px;
      margin-right: 5px;
    }
  }

  form {
    .confirm-form-btn {
      @include blue-contained-button;
      margin-top: 24px;
      width: 100%;
    }
  }
  .back-link,
  .send-new-link,
  .back-to-login,
  .return-to-portal {
    @include text-only-button(underline);
    color: $InitioBlue;
    width: 100%;
  }
  h4{
    @include headline-medium;
    color: $LightOnSurface;
    text-align: center;
    margin: 0;
  }
  h5{
    @include body-large;
    margin: 0;
  }
}
