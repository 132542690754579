$Primary100: #FFFFFF;
$Primary99: #FDFCFF;
$Primary95: #EAF1FF;
$Primary90: #D1E4FF;
$Primary80: #9ECAFF;
$Primary70: #63B0FF;
$Primary60: #0095F7;
$Primary50: #007BCC;
$Primary40: #0061A4;
$Primary30: #00497D;
$Primary20: #003258;
$Primary10: #001D36;
$Primary0: #000000;

$Secondary100: #FFFFFF;
$Secondary99: #FEFBFF;
$Secondary95: #EDF0FF;
$Secondary90: #DCE2F9;
$Secondary80: #C0C6DC;
$Secondary70: #A4ABC0;
$Secondary60: #8A90A5;
$Secondary50: #70778B;
$Secondary40: #575E71;
$Secondary30: #404659;
$Secondary20: #293042;
$Secondary10: #141B2C;
$Secondary0: #000000;

$Tertiary100: #FFFFFF;
$Tertiary99: #FFFBFF;
$Tertiary95: #F1EFFF;
$Tertiary90: #DFE0FF;
$Tertiary80: #BDC2FF;
$Tertiary70: #9AA4FF;
$Tertiary60: #7C88F1;
$Tertiary50: #626ED5;
$Tertiary40: #4854BA;
$Tertiary30: #2F3BA1;
$Tertiary20: #13208A;
$Tertiary10: #000965;
$Tertiary0: #000000;

$Error100: #FFFFFF;
$Error99: #FFFBFF;
$Error95: #FFEDEA;
$Error90: #FFDAD6;
$Error80: #FFB4AB;
$Error70: #FF897D;
$Error60: #FF5449;
$Error50: #DE3730;
$Error40: #BA1A1A;
$Error30: #93000A;
$Error20: #690005;
$Error10: #410002;
$Error0: #000000;

$Neutral100: #FFFFFF;
$Neutral99: #FDFCFF;
$Neutral95: #E9F1FF;
$Neutral90: #D1E4FF;
$Neutral80: #9DCAFF;
$Neutral70: #6DB0F6;
$Neutral60: #5095D9;
$Neutral50: #307BBE;
$Neutral40: #0061A2;
$Neutral30: #00497C;
$Neutral20: #003257;
$Neutral10: #001D36;
$Neutral0: #000000;

$NeutralVariant100: #FFFFFF;
$NeutralVariant99: #FDFCFF;
$NeutralVariant95: #EDF1FA;
$NeutralVariant90: #DFE2EB;
$NeutralVariant80: #C3C7CF;
$NeutralVariant70: #A7ABB4;
$NeutralVariant60: #8D9199;
$NeutralVariant50: #73777F;
$NeutralVariant40: #5A5F66;
$NeutralVariant30: #43474E;
$NeutralVariant20: #2C3137;
$NeutralVariant10: #171C22;
$NeutralVariant0: #000000;

$Success100: #FFFFFF;
$Success99: #F4FFF7;
$Success95: #BDFFDE;
$Success90: #68FCC3;
$Success80: #46DFA8;
$Success70: #13C28E;
$Success60: #00A577;
$Success50: #008862;
$Success40: #006C4D;
$Success30: #005139;
$Success20: #003826;
$Success10: #002115;
$Success0: #000000;

$Warning100: #FFFFFF;
$Warning99: #FFFBFF;
$Warning95: #FFEED8;
$Warning90: #FFDEA8;
$Warning80: #FABC3F;
$Warning70: #DCA124;
$Warning60: #BD8700;
$Warning50: #9C6F00;
$Warning40: #7C5800;
$Warning30: #5E4200;
$Warning20: #422D00;
$Warning10: #271900;
$Warning0: #000000;

/*******************************************************************************/
// INITIO/PRIMARY

$BlueBlack: #001C39;
$DarkBlue: #00315D;
$DeepBlue: #004884;
$RichBlue: #387DDF;
$InitioBlue: #4295F3;
$MidBlue: #A4C9FF;
$LightBlue: #D4E3FF;

// INITIO/TERTIARY
$InitioPurple: #707ADB;
$Purple2: #5C67B2;
$DeepPurple: #2F3677;
$DarkPurple: #151A51;

// INITIO/SECONDARY
$CoolSteel: #DOD9EE;
$LightSteel: #C7CBDC;
$BlackSteel: #121D21;
$MidnightSteel: #32374B;
$BlueSteel: #303D5C;

// INITIO/NEUTRAL

$DarkOutline: #999BA5;
$Outline: #CCCCCC;
$Container1: #EAE9EC;
$Container4: #DDD9DE;
$Container2: #FAFAFD;
$OffWhite: #FDFEFF;
$Background2: #EFF0F3;
$Background1: #F0F0F3;
$MainBackground: #EAEAED;

// INITIO/REDS

$InitioOrange: #EB603D;
$MidOrange: #FFB4A2;
$LightOrange: #FFDAD2;
$InitioRed: #E24949;
$MidRed: #F4B9B9;
$LightRed: #FFDADA;

// INITIO/GREENS

$DataGreen: #40C486;
$DarkGreen: #03251C;
$DeepGreen: #05382A;
$Evergreen: #085E46;
$InitioGreen: #F0F0F3;
$MidGreen: #88DDC6;
$LightGreen: #CFF1E8;
$BackgroundGreen: #CFF1E8;

// INITIO/GRADIENT

@mixin initio-gradient {
  background: linear-gradient(161.09deg, #C5D3E8 8.61%, #E5EBF7 35.51%, #ACBDDA 106.03%);
  transform: rotate(90deg);
}

// INITIO/YELLOWS

$InitioYellow: #F4C543;
$MidYellow: #FFDF94;
$LightYellow: #FFEFD0;

/*******************************************************************************/
// Light Mode

$LightPrimary: $Primary40;
$LightOnPrimary: $Primary100;
$LightPrimaryContainer: $Primary90;
$LightOnPrimaryContainer: $Primary10;

$LightSecondary: $Secondary40;
$LightOnSecondary: $Secondary100;
$LightSecondaryContainer: $Secondary90;
$LightOnSecondaryContainer: $Secondary10;

$LightTertiary: $Tertiary40;
$LightOnTertiary: $Tertiary100;
$LightTertiaryContainer: $Tertiary90;
$LightOnTertiaryContainer: $Tertiary10;

$LightError: #B3261E;
$LightOnError: $Error100;
$LightErrorContainer: $Error90;
$LightOnErrorContainer: $Error10;

$LightBackground: #FAFAFD;
$LightOnBackground: #121D21;
$LightSurface: #EFF0F3;
$LightOnSurface: #303D5C;

$LightSurfaceVariant: $NeutralVariant90;
$LightOnSurfaceVariant: $NeutralVariant30;

$LightOutline: $NeutralVariant50;

$LightSuccess: $Success40;
$LightOnSuccess: $Success100;
$LightSuccessContainer: $Success90;
$LightOnSuccessContainer: $Success10;

$LightWarning: $Warning40;
$LightOnWarning: $Warning100;
$LightWarningContainer: $Warning90;
$LightOnWarningContainer: $Warning10;

// Light Surface

$LightSurface: #FAFAFD;

@mixin light-surface-helper($priPercentage) {
  background: linear-gradient(
    0deg,
    rgba(0, 97, 164, $priPercentage),
    rgba(0, 97, 164, $priPercentage)
  ),
  $LightSurface;
}

@mixin light-surface-1 {
  @include light-surface-helper(0.05);
}
@mixin light-surface-2 {
  @include light-surface-helper(0.08);
}
@mixin light-surface-3 {
  @include light-surface-helper(0.11);
}
@mixin light-surface-4 {
  @include light-surface-helper(0.12);
}
@mixin light-surface-5 {
  @include light-surface-helper(0.14);
}

/*******************************************************************************/
// Dark Mode

$DarkPrimary: $Primary80;
$DarkOnPrimary: $Primary20;
$DarkPrimaryContainer: $Primary30;
$DarkOnPrimaryContainer: $Primary90;

$DarkSecondary: $Secondary80;
$DarkOnSecondary: $Secondary20;
$DarkSecondaryContainer: $Secondary30;
$DarkOnSecondaryContainer: $Secondary90;

$DarkTertiary: $Tertiary80;
$DarkOnTertiary: $Tertiary20;
$DarkTertiaryContainer: $Tertiary30;
$DarkOnTertiaryContainer: $Tertiary90;

$DarkError: $Error80;
$DarkOnError: $Error20;
$DarkErrorContainer: $Error30;
$DarkOnErrorContainer: $Error90;

$DarkBackground: $Neutral10;
$DarkOnBackground: $Neutral90;
$DarkBackgroundContainer: $Neutral10;
$DarkOnBackgroundContainer: $Neutral80;

$DarkSurfaceVariant: $NeutralVariant30;
$DarkOnSurfaceVariant: $NeutralVariant80;

$DarkOutline: $NeutralVariant60;

$DarkSuccess: $Success80;
$DarkOnSuccess: $Success20;
$DarkSuccessContainer: $Success30;
$DarkOnSuccessContainer: $Success90;

$DarkWarning: $Warning80;
$DarkOnWarning: $Warning20;
$DarkWarningContainer: $Warning30;
$DarkOnWarningContainer: $Warning90;

// Light Surface

$DarkSurface: #001D36;

@mixin dark-surface-helper($priPercentage) {
  background: linear-gradient(
    0deg,
    rgba(158, 202, 255, $priPercentage),
    rgba(158, 202, 255, $priPercentage)
  ),
  $DarkSurface;
}

@mixin dark-surface-1 {
  @include dark-surface-helper(0.05);
}
@mixin dark-surface-2 {
  @include dark-surface-helper(0.08);
}
@mixin dark-surface-3 {
  @include dark-surface-helper(0.11);
}
@mixin dark-surface-4 {
  @include dark-surface-helper(0.12);
}
@mixin dark-surface-5 {
  @include dark-surface-helper(0.14);
}

/*******************************************************************************/

$WhiteContainerBoxShadow: rgba(199, 203, 220, 0.85);

$ToolTipBackgroundGrey: rgba(48, 61, 92, 0.08);