@import "../../styles/palette.scss";
@import "../../styles/typography.scss";
$componentWidth: 880px;

.FinalHomePage {
  margin: 0 auto;
  width: $componentWidth;
  &.loading-transactions-data {
    margin: 60px auto 0px;
    color: $Outline;
    display: block;
  }
  > h4 {
    @include headline-large("Manrope");
    margin-top: 63px;
    margin-bottom: 41px;
    .user-name {
      color: $Primary50;
    }
  }
  > .content-wrapper {
    width: $componentWidth;
    left: 200px;
    top: 201px;
    background: $Background1;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    border-radius: 24px;

    padding-left: 47px;
    padding-right: 47px;
    padding-bottom: 30px;
    padding-top: 32px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    > p {
      padding-bottom: 20px;
    }
    > .sandbox-report-review-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 12px;
      height: 250px;
    }
  }
  .TransactionInfoBlock {
    .overlay {
      position: absolute;
      border-radius: 12px;
      height: 250px;
      width: 246px;
      &:hover {
        cursor: pointer;
        background: rgba(0, 72, 132, 0.08);
      }
    }
    .in-progress {
      background-color: #EDF1FA;
      padding: 4px 8px;
      height: 28px;
      font-family: "Roboto";
      font-style: normal;
      width: 100px;
      font-weight: 600;
      font-size: 12px;
      margin-top: 4px;
      text-align: center;
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: 24px;
    }
    border-radius: 12px;
    > .title-background-image {
      height: 150px;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      &.report-homepage-thumb {
        background-image: url(../../images/409A_Report_Homepage_Thumbnail.svg);
      }
      &.sandbox-homepage-thumb {
        background-image: url(../../images/409A_Sandbox_Homepage_Thumbnail.svg);
      }
      &.submission-homepage-thumb {
        background-image: url(../../images/409A_SubmissionForm_Homepage_Thumbnail.svg);
      }
    }
    &.item {
      width: 246px;
      background-color: $LightBackground;
      color: #49454f;
      border: 1px solid #b9bdc3;
      filter: drop-shadow(2px 5px 4px rgba(0, 49, 93, 0.2));
      > .transaction-content {
        padding-left: 16px;
        padding-right: 22px;
        padding-bottom: 21px;
        padding-top: 19px;
        min-height: 100px;

        flex-wrap: nowrap;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        > .left-side {
          > .final-info {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            display: block;
          }
          > .sub-label {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.4px;
          }
          > .title {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
        > .right-side {
          > .vertical-ellipsis-icon-wrapper {
            $heightWidth: 30px;
            height: $heightWidth;
            width: $heightWidth;
            border-radius: calc($heightWidth / 2);
            border: 0px;
            background-color: transparent;
            flex-wrap: nowrap;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
              background-color: #e1eaf3;
            }
          }
        }
      }
    }
  }
}
