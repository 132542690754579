@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";
@import "../../styles/palette.scss";

.VerifyEmail {
  .loading-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background: #fafafd;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    .custom-loading-spinner {
      $spinnerSize: 62px;
      height: $spinnerSize;
      width: $spinnerSize;
      @include spin-infinitely();
    }
  }
  .code-is-expired,
  .new-verif-email {
    @include white-container(448px);
    @include spaced-between-flexbox(center, nowrap, column);
    gap: 20px;
    align-items: center;
    margin: 64px auto;
    .top-icon {
      width: 21px;
      height: 18px;
    }
    h4 {
      @include headline-medium;
      text-align: center;
      margin: 0;
    }
    .send-new-link {
      @include blue-contained-button;
      margin-top: 8px;
      width: 100%;
    }
    .login-link {
      @include btn-to-link-text;
      color: $InitioBlue;
      padding: 0;
    }
  }
  .new-verif-email {
    .top-icon {
      color: #00BE8A;
      width: 28px;
      height: 28px;
    }
  }
}
